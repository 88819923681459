<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/ios">ios</router-link>
      <router-link to="/android">android</router-link>
      <router-link to="/scanner/android">scanner android</router-link>
      <router-link to="/scanner/ios">scanner ios</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="sass">
#app,
html
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
  background: #59B7D2

body, html
  position: relative
  height: 100%

#app
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)


#nav 
  padding: 30px

  a
    font-weight: bold
    color: #2c3e50
    margin: 10px

    &.router-link-exact-active
      color: #42b983
</style>
