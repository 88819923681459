<template>
  <div class="home">
    <h1>{{version.appName}}</h1>
    <div class="logo-container">
      <img :src="version.logo" :alt="version.appName">
    </div>
    <a class="button" :href="version.url">Download {{version.appName}} for {{version.platform}}</a>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      versions: {
        'Ios': {
          appName: 'PinQuest',
          platform: 'iOS',
          url: 'itms-services://?action=download-manifest&url=https://pinquest.marvelous.digital/builds/ios/manifest.plist',
          logo: 'https://pinquest.marvelous.digital/builds/ios/PinquestLogoDev.png'
        },
        'Android': {
          appName: 'PinQuest',
          platform: 'Android',
          url: 'https://pinquest.marvelous.digital/builds/android/pinquest.apk',
          logo: 'https://pinquest.marvelous.digital/builds/ios/PinquestLogoDev.png'
        },
        'DevIos': {
          appName: 'PinQuest Dev',
          platform: 'iOS',
          url: 'itms-services://?action=download-manifest&url=https://pinquest.marvelous.digital/builds/ios/manifest.plist',
          logo: 'https://pinquest.marvelous.digital/builds/ios/PinquestLogoDev.png'
        },
        'DevAndroid': {
          appName: 'PinQuest Dev',
          platform: 'Android',
          url: 'https://pinquest.marvelous.digital/builds/android/pinquest.apk',
          logo: 'https://pinquest.marvelous.digital/builds/ios/PinquestLogoDev.png'
        },
        'ScannerAndroid': {
          appName: 'PinQuest Scanner',
          platform: 'Android',
          url: 'https://pinquest.marvelous.digital/builds/android/scan/PinquestScanner.apk',
          logo: 'https://pinquest.marvelous.digital/builds/ios/scan/image.512x512.png'
        },
        'ScannerIos': {
          appName: 'PinQuest Scanner',
          platform: 'iOS',
          url: 'itms-services://?action=download-manifest&url=https://pinquest.marvelous.digital/builds/ios/scan/manifest.plist',
          logo: 'https://pinquest.marvelous.digital/builds/ios/scan/image.512x512.png'
        }
      },
    }
  },
  computed: {
    version() {
      return this.versions[this.$route.name]
    }
  },
}
</script>

<style lang="sass" scoped>
  .home
    h1
      color: white

    .logo-container 
      display: block
      height: 200px
      width: 200px
      margin: 20px auto
      background: white
      border-radius: 25px
      overflow: hidden
      img
        width: 100%
        height: 100%

    .button
      display: inline-block
      padding: 13px 40px
      text-decoration: none
      color: white
      font-weight: bold
      background: #FF7A00
      border-radius: 16px
      box-shadow: 0 4px 0 #C9741C
      transition: all .2s ease-in-out
      &:hover
        box-shadow: 0 2px 0 #C9741C
</style>