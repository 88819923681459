import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: {
      name: 'Android'
    }
  },
  {
    path: '/ios',
    name: 'Ios',
    meta: {
      title: 'Pinquest Download',
    },
    component: function() {
      return import ( /* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
  {
    path: '/android',
    name: 'Android',
    meta: {
      title: 'Pinquest Download',
    },
    component: function() {
      return import ( /* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
  {
    path: '/dev/ios',
    name: 'DevIos',
    meta: {
      title: 'Pinquest Download',
    },
    component: function() {
      return import ( /* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
  {
    path: '/dev/android',
    name: 'DevAndroid',
    meta: {
      title: 'Pinquest Download',
    },
    component: function() {
      return import ( /* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
  {
    path: '/scanner/ios',
    name: 'ScannerIos',
    meta: {
      title: 'Pinquest Scanner Download',
    },
    component: function() {
      return import ( /* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
  {
    path: '/scanner/android',
    name: 'ScannerAndroid',
    meta: {
      title: 'Pinquest Scanner Download',
    },
    component: function() {
      return import ( /* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router